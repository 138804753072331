import packageJson from '../../package.json';
import { Environment } from './interfaces';

export const environment: Environment = {
    production: true,
    version: packageJson.version,
    configPath: 'config.json',
    chatbot: {
        chatBase: '/assets/scripts/chatbase/chatbase.js',
    },
    federation: {
        manifest: '/assets/federation.manifest.dev.json',
    },
    imports: [],
};
